import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import excel from 'vue-excel-export'
import VueToastr from "vue-toastr";
 
Vue.use(excel)
Vue.use(VueToastr); 

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo(0, 0)
  next()
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
