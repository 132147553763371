<template>
  <div class="home">
    <hero></hero>
    <happy-customers></happy-customers>
    <trusted-by></trusted-by>
    <why-us></why-us>
    <latest-news></latest-news>
    <testimonials></testimonials>
    <how-it-works></how-it-works>
    <blog></blog>
    <faq></faq>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/home/Hero.vue'
import TrustedBy from '@/components/home/TrustedBy'
import WhyUs from '@/components/home/WhyUs'
import LatestNews from '../components/home/LatestNews.vue'
import Testimonials from '../components/home/Testimonials.vue'
import HowItWorks from '../components/home/HowItWorks.vue'
import Faq from '../components/home/Faq.vue'
import Blog from '../components/home/Blog.vue'
import HappyCustomers from '../components/home/HappyCustomers.vue'

export default {
  name: 'Home',
  components: {
    Hero,HappyCustomers,TrustedBy,WhyUs,LatestNews,Testimonials,HowItWorks,Faq,Blog
  }
}
</script>
