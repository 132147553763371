<template>
  <v-app-bar app color="white" elevation="0" light class="app-bar-container">
    <div
      class="d-flex align-center app-bar-menu"
      
    >
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="62px"
        @click="home"
      />

      <div>
        <v-btn text>
          <span class="mr-2" @click="dashboard">Account</span>
        </v-btn>
        <v-btn text>
          <span class="mr-2" @click="taxGuide">Tax Guide</span>
        </v-btn>
        <v-btn text>
          <span class="mr-2">Export Data</span>
        </v-btn>
        <v-btn text>
          <span class="mr-2" @click="myWallet">My Wallet</span>
        </v-btn>
        <v-btn text>
          <span class="mr-2" @click="report">Reports</span>
        </v-btn>
        <!-- <v-btn text @click="howItWork">
          <span class="mr-2">How it works?</span>
        </v-btn> -->
        <v-btn text @click="about">
          <span class="mr-2">About Us</span>
        </v-btn>
        <v-btn text @click="pricing">
          <span class="mr-2">Pricing</span>
        </v-btn>
      </div>
      <v-btn rounded large dark class="teal"  v-if="USER.email"
      >
        <span class="mr-2" @click="logout">Logout</span>
      </v-btn>
      <v-btn rounded large dark class="teal" v-else
      >
        <span class="mr-2" @click="signin">Sign In</span>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<style scoped>
.app-bar-container {
  height: 88px !important;
  padding-top: 12px;
  padding-left: 56px;
  padding-right: 56px;
}
.app-bar-menu{
    justify-content: space-between; width: 100%
}
.v-btn{
    text-transform: capitalize;font-size: 16px;
}
</style>

<script>
import { mapGetters } from 'vuex'
export default {
  data(){return{}},
  methods : {
    signin(){this.$router.push('/signin')},
    dashboard(){this.$router.push('/dashboard')},
    pricing(){this.$router.push('/pricing')},
    about(){this.$router.push('/about')},
    report(){this.$router.push('/reports')},
    howItWork(){
      this.$router.push('/how-it-works')
    },
    logout(){
      this.$store.dispatch('SET_USER',{})
      this.$router.push('/')
    },
    myWallet(){this.$router.push('/my-wallet')},
    taxGuide(){
      this.$router.push('/tax-guide')
    },
    home(){
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['USER'])
  },
  mounted(){
    
  }
}
</script>