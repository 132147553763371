<template>
    <div style="  
     background: linear-gradient(100deg, #F0F0FC -11.73%, rgba(240, 240, 252, 0.00) 104.43%);padding: 50px 0px;
    ">
    <v-container class="pl-10 pr-10 text-center">
        <h1 class="text-center mt-10 mb-3 ">Frequently Asked <span class="underline-text">Questions</span></h1>
            <p class="text-center mb-10">Some frequently asked questions.</p>
        <v-expansion-panels>
    <v-expansion-panel v-for="(item, i) in faq" :key="i" class="mt-5 pt-5 pb-5" style="border-radius: 5px;">
      <v-expansion-panel-header> <b>{{ item.q }}</b> </v-expansion-panel-header>
      <v-expansion-panel-content>
       <div v-html="item.a" style="text-align: left;"></div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
    </v-container>
  </div>
</template>


<script>
export default {
    data(){
        return{
            faq : [
                {
                    q : "What triggers a Taxable Event in Crypto Trading?",
                    a : "<p>Taxable transfers of crypto may include:</p><ul><li>Selling crypto for INR or another fiat currency.</li><li>Trading crypto for crypto, including stablecoins.</li><li>Spending crypto on goods and services.</li><li>Gifting crypto.</li><li>Mining coins is potentially income from other sources.</li><li>Getting paid a salary in crypto.</li><li>Staking rewards.</li><li>Mining tokens.</li><li>Airdrops.</li></ul>"
                },
                {
                    q: "What are the current Tax Implications for Crypto Trading?",
                    a: "<p>Profit from the transfer of any virtual digital asset is to be taxed at the rate of 30%.</p><p>Profits are seen as income, taxed under section 115.</p><p>There is no opportunity to treat cryptocurrency as a capital asset.</p><p>Discounted tax on long term versus short term gains is not an option.</p><p>No deduction, except the cost of acquisition, will be allowed while reporting income from the transfer of digital assets.</p><p>A TDS of 1% is to be charged on payments of virtual assets, above a certain threshold(yet to be defined).</p><p>Recipients of a crypto gift will be taxed, as if their gift was a form of income.</p>"
                },
                {
                    q: "Is Crypto Legal?",
                    a: "<p>Cryptocurrency is not legal tender in India. While cryptocurrency in India can be exchanged due to the absence of a robust regulatory framework, a protracted licensing process makes it very difficult for certain service providers’ technologies to operate. The chairman of the Central Board of Direct Taxation has said that anyone making profits from Cryptocurrency's will have to pay taxes on them</p>",
                },
                {
                    q: "What is the tax laibility for crypto gifting?",
                    a: "According to the latest proposal, the recipient of a cryptocurrency gift will be subject to a 30 percent flat rate of income tax. This holds true for all cryptocurrency assets, including NFTs.",
                },
                
            ]
        }
    }
}
</script>

<style scoped>
.underline-text {
  position: relative;
  display: inline-block;
  color: #4aabab;
}
.underline-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -20px;
  right: 0;
  stroke: 3px;
  width: 200px;
  height: 30px;
  background-image: url("../../assets/welcome.png");
  /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  background-size: cover;
}
</style>