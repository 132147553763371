<template>
  <v-app>
   
    <app-bar></app-bar>
    <v-main style="margin-top: 100px;">
      <router-view/>
      <app-footer></app-footer>
    </v-main>
    
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue'
import AppFooter from './components/Footer.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components: {
    AppBar,AppFooter
  }
};
</script>

<style>
  #app{
    font-family: 'Raleway', sans-serif;
    background: #FbFbFb;
  }
  @media (min-width: 1904px){
    .container {
    max-width: 1185px;
}
  }


</style>
