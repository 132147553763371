import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user : {},
    jsonData : {},
    progress : false,
    drawer : true,
    transactions : [],
    coin_balance : [],
    pnl : []
  },
  getters : {
    USER : state =>{
      return state.user
    },
    PROGRESS : state => {
      return state.progress
    },
    DRAWER  : state => {
      return state.drawer
    },
    JSON_DATA : state => {
      return state.jsonData
    },
    TRANSACTIONS : state => {
      return state.transactions
    },
    COIN_BALANCE : state => {
      return state.coin_balance
    },
    PNL : state => {
      return state.pnl
    },
    PNL_TOTAL : state => {
     
        var totalPnl = 0
      
        state.pnl.forEach((record) => {
          const { coin, pnl } = record
          totalPnl += pnl;
        });
  
        return totalPnl;
      
    },
    TDS_TOTAL : state => {
      var totalTds = 0

      state.transactions.forEach((record) => {
        const { tds } = record
        totalTds += parseFloat(tds || 0);
      });

      return totalTds;
    }
  },
  mutations: {
    SET_USER : (state,payload) => {
      state.user = payload
    },
    SET_PROGRESS : (state,payload) => {
      state.progress = payload
    },
    SET_DRAWER : (state,payload) => {
      state.drawer = payload
    },
    SET_JSON_DATA : (state,payload) => {
      state.jsonData = payload
    },
    SET_TRANSACTIONS : (state,payload) => {
      state.transactions = payload
    },
    SET_COIN_BALANCE : (state,payload) => {
      state.coin_balance = payload
    },
    SET_PNL : (state,payload) => {
      state.pnl = payload
    }
  },
  actions: {
    SET_USER : (context,payload) => {
      context.commit("SET_USER",payload)
    },
    SET_PROGRESS : (context,payload) => {
      context.commit("SET_PROGRESS",payload)
    },
    SET_DRAWER : (context,payload) => {
        context.commit("SET_DRAWER",payload)
    },
    SET_JSON_DATA : (context,payload) => {
      context.commit("SET_JSON_DATA",payload)
    },
    SET_TRANSACTIONS : (context,payload) => {
      context.commit("SET_TRANSACTIONS",payload)
    },
    SET_COIN_BALANCE : (context,payload) => {
      context.commit("SET_COIN_BALANCE",payload)
    },
    SET_PNL : (context,payload) => {
      context.commit("SET_PNL",payload)
    }
  },
  modules: {
  }
})
