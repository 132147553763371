import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('../views/auth/SignIn.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/auth/SignUp.vue')
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import('../views/Features.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/my-wallet',
    name: 'MyWallet',
    component: () => import('../views/MyWallet.vue')
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../views/Pricing')
  },{
    path: '/how-it-works',
    name: 'HowItWorks',
    component: () => import('../views/HowItWorks')
  },
  {
    path: '/tax-harvesting',
    name: 'TaxHarvesting',
    component: () => import('../views/TaxHarvesting')
  },
  {
    path: '/tax-guide',
    name: 'TaxGuide',
    component: () => import('../views/TaxGuide')
  },
  {
    path: '/audit',
    name: 'Audit',
    component: () => import('../views/Audit')
  },
  {
    path: '/wallet-transactions',
    name: 'WalletTransactions',
    component: () => import('../views/WalletTransactions')
  },
  {
    path: '/upload-custom-csv',
    name: 'UploadCustomCsv',
    component: () => import('../views/UploadCustomCSV')
  },
  {
    path : '/reports',
    name : 'Reports',
    component : () => import('../views/Reports')
  }
]

const router = new VueRouter({
  routes
})

export default router
